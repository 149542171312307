
.forgotPassword {
 
    background-color: rgba(228, 228, 228, 0.7);
    width: 280px;
    height: 330px;
    border-radius: 20px;
    box-shadow: 3px 3px 10px rgb(211, 207, 207);
    padding: 20px;
    transition: all 0.3s ease-in-out;
}


.forgotPassword:hover {
    box-shadow: inset 3px 3px 10px rgb(211, 207, 207);
}

.logoContainer{
    height: 50px;
    display: flex;
    justify-content: center;
}

.logo {
    object-fit: contain;
    height: 100%;
    width: 100%;
   
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}